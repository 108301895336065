.info-bar-wrapper {
  &:last-child {
    border: none !important;
  }
  margin: 50px 0;
  align-items: center;
  &.reverse {
    .left {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .logo-wrapper {
        & > div {
          float: right;
        }
      }
    }
    p {
      margin: 0 0 0 5%;
    }
  }
  .left {
    padding-top: 5%;

    .group-title {
      font-size: 65px;
      color: #838383;
      font-weight: bold;
    }

    > p {
      font-size: 16px;
      color: #030303;
      margin-top: 50px;
      margin-bottom: 50px;
      padding: 0 2px;
      width: 95%;
    }

    .firms-title {
      color: #838383;
      font-size: 45px;
      font-weight: bold;
    }

    .button {
      background-color: primary(500);
      color: light-neutral(0);
      padding: 0 25px;
      line-height: 40px;
      border-radius: 20px;
      &:hover {
        background-color: primary(300);
      }
    }

    .logo-wrapper {
      margin-top: 20px;
      .image {
        height: 50px;
        max-width: 220px;
      }
      & > div {
        margin: 15px;
        float: left;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .info-bar-wrapper {
    .left {
      .group-title {
        font-size: 30px;
      }
      p {
        font-size: 14px;
        margin: 10px 0 0 0;
        padding: 0 1.5px;
        width: 100%;
        // color: neutral(0);
      }
      .firms-title {
        font-size: 20px;
      }
    }
    .logo-wrapper {
      // transform: scale(0.6);
      .image {
        height: 35px !important;
      }
    }
  }
}
