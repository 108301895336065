.brand-card-list {
  margin: 0;
  width: 100%;
  .brand-card-item {
    margin-bottom: 75px;
    display: flex;
    flex-direction: column;
    padding: 0 60px;
    text-align: center;
    .title {
      color: #838383;
      font-size: 25px;
      margin-bottom: 20px;
      line-height: 35px;
      font-weight: bold;
      &.bottom {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #fff;
        min-height: 40px;
      }
    }
    .description {
      color: #ffffff;
      font-size: 18px;
      margin-bottom: 20px;
    }
    .link-wrapper {
      display: flex;
      margin-bottom: 20px;
      justify-content: space-around;
      a {
        width: 25%;
        font-size: 20px;
        color: #838383;
        text-transform: uppercase;
        font-weight: bold;
        background-color: dark-neutral(10);
        text-align: center;
        line-height: 40px;
        border-radius: 5px;
        transition: all, .4s;
        text-shadow: 1px 1px 1px dark-neutral(0);
        &:hover {
          background-color: dark-neutral(30);
          transition: all, .4s;
        }
      }
    }
    .pdf-link {
      position: relative;
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        background-color: rgba(dark-neutral-rgb(0), 0.8);
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        opacity: 0;
        transition: all, .4s;
      }
      .c-icon {
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 2;
        font-size: 50px;
        transform: translateX(-50%) translateY(-50%);
        opacity: 0;
        transition: all, .4s;
        transition-delay: .4s;
        &::before {
          color: #FFF;
          text-shadow: 1px 1px 1px dark-neutral;
        }
      }
    }
    .image {
      height: auto;
      width: 100%;
    }
    &:hover {
      .pdf-link {
        &::before {
          opacity: 1;
          transition: all, .4s;
        }
        .c-icon {
          top: 50%;
          opacity: 1;
          transition: all, .4s;
          transition-delay: .4s;
        }
      }
    }
  }
}

@media #{$media-lg} {
  .brand-card-list {
    margin: 0 -25px;
    .brand-card-item {
      padding: 25px;
      .title {
        font-size: 20px;
        margin-bottom: 15px;
      }
      .description {
        font-size: 12px;
      }
      .link-wrapper {
        a {
          font-size: 16px;
        }
      }
    }
  }
}
@media #{$media-md} {
}
@media #{$media-sm} {
  .brand-title {
    font-size: 30px !important;
    text-align: center !important;
  }
  .brand-card-list {
    margin: 0;
    .brand-card-item {
      padding: 0;
      .title {
        font-size: 23pt;
        margin-bottom: 15px;
      }
      .description {
        font-size: 14pt;
      }
      .link-wrapper {
        a {
          font-size: 11pt;
        }
      }
      .image {
        width: 100%;
      }
    }
  }
}
@media #{$media-xs} {
  .brand-card-list {
    .brand-card-item {
      margin-bottom: 15px;
      .title {
        font-size: 23px;
        margin-bottom: 15px;
        &.bottom {
          font-size: 20px;
        }
      }
      .description {
        font-size: 14px;
      }
      .link-wrapper {
        a {
          font-size: 11px;
        }
      }
    }
  }
}
